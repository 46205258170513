import {GET_EXCEL_M_CIRCLES, ERROR_EXCEL_M_CIRCLES} from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';

const apiV2Url = 'https://rapport.moose-software.tech/api/v3/pois/excelmcircles';

export const excelMCircles = ( circles ) => {
    const emstoken = localStorage.getItem('emstoken');
    debugger;
    return (dispatch) => {
        return axios.post(`${apiV2Url}` , {circles}, { headers: { emstoken: emstoken }})
            .then(response => {
                debugger;
                window.open(response.data.url)
                //dispatch(getSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const getSuccess =  (data) => {
    return {
        type: GET_EXCEL_M_CIRCLES,
        payload: data
        
    }
};


export const error =  (data) => {
    return {
        type: ERROR_EXCEL_M_CIRCLES,
        payload: data
        
    }
};

