import {RESET_FILTER, SAVE_FILTERS} from "./types";

export function saveFilters(payload) {
    return {
        type: SAVE_FILTERS,
        payload: payload
    };
}

export function resetFilter(payload) {
    return {
        type: RESET_FILTER,
        payload: payload
    };
}
