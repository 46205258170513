import React, { Component, Fragment } from 'react';
import AsyncPaginate from 'react-select-async-paginate';

import Grid from '@material-ui/core/Grid';
import './../style/_header.scss';

type Props = {

};

type State = {
    filters: Array,
    value: string,
    params: Array,
    circuito: any
};

class Filter extends Component<Props, State> {

    constructor() {
        super();

        this.state = {
            filtersList: [],
            allList: [],
            listAfterSearch: [],
            value: '',
            params: [],
            filterByParent: {},
            filters: {},
            sliceLast: 0,
            inputIndex: 0
        };
        this.resetFilters = this.resetFilters.bind(this);
    }

    componentDidMount() {
        if (this.props.filters) {
            let i = 0;
            const newArray = [];

            for (i = 0; i < Object.keys(this.props.filters[0]).length; i++) {
                if (this.props.filters[0][i] !== undefined) {
                    newArray.push(this.props.filters[0][i]);
                }
            }

            if (this.props.poi.length > 0) {
                const length = this.props.poi.length - 1;

                this.setState({
                    filtersList: this.props.poi[length].filters.map(element => {
                        return {
                            name: element.name,
                            values: element.values.filter(value => !!value)
                        }
                    }),
                    allList: this.state.filtersList
                });
            } else {
                this.setState({
                    filtersList: newArray,
                    allList: this.state.filtersList
                });
            }

            this.setFilters('start');
        }


        setTimeout(() => {
            let selectEvent = this.getSingleIndex();

            selectEvent.forEach((select, index) => {
                select.addEventListener('click', () => {
                    this.setState({ inputIndex: +index, sliceLast: 0 });
                });
            })
        }, 250);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.poi !== prevProps.poi) {
            const length = this.props.poi.length - 1;

            this.setState({ filtersList: this.props.poi[length].filters }, () => {
                this.props.updateFilters(this.state.filters);
            });
        }

        if (this.props.loader !== prevProps.loader) {
            let root = document.getElementById('root');
            let modal = document.getElementsByClassName('ReactModal__Overlay--after-open')[0];

            if (this.props.loader) {
                root.style.pointerEvents = 'none';
                root.style.overflowY = 'hidden';
                modal.style.pointerEvents = 'none';
            } else {
                root.style.pointerEvents = 'all';
                modal.style.pointerEvents = 'all';
                root.style.overflowY = 'auto';
            }
        }



    }

    delay = ms =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, ms);
        });

    loadOptions = async (value) => {
        await this.delay(500);

        if (!!this.state.filtersList) {
            let currentFilter = !!this.state.listAfterSearch && this.state.listAfterSearch.length > 0
                ? this.state.listAfterSearch[this.state.inputIndex]
                : this.state.filtersList[this.state.inputIndex];

            return {
                label: currentFilter ? currentFilter.name : 'filterdata',
                options: this.arrayOfValues(currentFilter) || [],
                hasMore: currentFilter.values.length > this.state.sliceLast
            }
        }
    }

    loadOptionsAfterSearch(...args) {
        if (!!this.state.filtersList) {
            let currentFilter = !!this.state.listAfterSearch && this.state.listAfterSearch.length > 0
                ? this.state.listAfterSearch[this.state.inputIndex]
                : this.state.filtersList[this.state.inputIndex];

            let newCurrentFilter = currentFilter.values.filter(item => {
                if (!!item) {
                    const itemLowerCase = item.toLowerCase();
                    const inputValue = args[0].toLowerCase();

                    return itemLowerCase.includes(inputValue);
                }
            });

            let searchOptions = newCurrentFilter.map(item => {
                return {
                    label: item,
                    value: item
                }
            });

            return {
                label: currentFilter.name,
                options: searchOptions,
                hasMore: false
            }
        }
    }

    arrayOfValues(currentFilter) {
        let lastState = this.state.sliceLast;
        let filterValue = currentFilter.values.slice(+lastState, +lastState + 20);

        this.setState({ sliceLast: +this.state.sliceLast + 20 });

        return filterValue.map(filter => {
            return {
                label: filter,
                value: filter,
            }
        });
    }

    getSingleIndex() {
        return document.querySelectorAll('.select');
    }

    removeFilter(arr, value) {
        return arr.filter(function (ele) {
            return ele !== value;
        });
    }

    handleChange(val, event) {
        this.setState({ params: val });
        let value = '';
        let parent = event.name || '';

        if (event.action === 'remove-value') {
            value = event.removedValue.value;

            let filterParent = this.state.filtersList.filter(filter => filter.name === parent);

            this.state.filters[parent] = this.removeFilter(filterParent[0].values, value);

            if (this.state.filters[parent].length === 0) {
                delete this.state.filters[parent];
            }
        } else if (event.action === 'clear') {
            this.setState({
                filters: []
            })
            this.props.resetFilter([]);
        } else {
            const valueArray = [];

            if (!!event && !!event.option && event.option.value) {
                value = event.option.value;
                valueArray.push(value);
            }

            this.setFilters(event, valueArray, parent);

            this.props.updateOpenFilter(true);
        }

        this.props.updateFilters(this.state.filters);
        this.props.saveFilters(this.state.filters);
        this.props.setfilters(this.state.filters);
    }

    setFilters(event, valueArray, parent) {
        if ((Object.entries(this.props.savedFilters).length !== 0 || this.props.savedFilters !== '') && event.action !== 'select-option') {
            this.setState({
                filters: this.props.savedFilters
            })
        } else {
            if (this.state.filters[parent] === undefined) {
                this.state.filters[parent] = valueArray;
            } else if (!!event && !!event.option && event.option.value) {
                this.state.filters[parent].push(event.option.value);
            } else {
                delete this.state.filters[parent];
            }
        }
    }

    wrappedLoadOptions = (...args) => {
        if (!args[0]) {
            return this.loadOptions();
        } else {
            return this.loadOptionsAfterSearch(...args);
        }
    };

    checklabel(value) {
        let label = ''
        let data = []

        Object.keys(this.props.savedFilters).forEach(function (item, key, a) {
            if (item === value.name) {
                let i = 0
                for (i = 0; this.props.savedFilters[item].length > i; i++) {
                    let obj = {}
                    label = this.props.savedFilters[item][i]
                    obj.label = label
                    obj.value = label
                    data.push(obj)
                }
            }
        }, this);

        if (label === '') {
            data = null
        }

        return data;
    }

    resetFilters(){
        this.props.resetFilter();
        const event = {}
        event.action = 'clear'
        this.handleChange('', event)
        this.setState({
            resetting: true
        })
    }


    render() {
        return (
            <Fragment>
                <Grid container spacing={4}>
                    {
                        this.props.filters.length > 0 ? this.state.filtersList.map((value, key, arr) => (
                            <Grid item xs={12} md={6} key={key}>
                                <p>{value.name}</p>
                                <AsyncPaginate
                                    name={value.name}
                                    value={!!this.props.setOpenFilter && this.props.setOpenFilter ? this.checklabel(value) : null}
                                    loadOptions={this.wrappedLoadOptions.bind(this)}
                                    onChange={this.handleChange.bind(this)}
                                    className={`select ${key}`}
                                    cache={false}
                                    isMulti
                                />
                            </Grid>
                        ))
                            : ''}
                </Grid>
            </Fragment>
        )
    }
}

export default Filter;
