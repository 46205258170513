import {CREATE_MAP_POST, UPDATE_MAP_POST, GET_MAP_POST} from '../actions/types';

export default function mapPostReducer(state = [], action) {
    switch (action.type) {
        case CREATE_MAP_POST:
            return action.payload;
        case UPDATE_MAP_POST:
            return action.payload;
        case GET_MAP_POST:
            return action.payload;
        default:
            return state;
    }
}