import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Export from './../container/export';

import './../style/_loader.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SuccessUpCircle extends Component<Props, State> {

    state = {
        openDialog: true,
        startExport: false
    }

    goToExport(){
        this.setState({
            startExport: true
        })
    }

    stopExport(){
        this.setState({
            startExport: false
         })

         this.props.resetUpCircle(false);
    }

    closeDialog() {
        this.setState({
            openDialog: false,
            startExport: true
        })
    }

    render() {
        return (
            <Fragment>
                <Dialog
                    open={this.state.openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.closeDialog.bind(this)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Distanze calcolate con successo"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.closeDialog.bind(this)} variant="contained" color="secondary">
                            Esporta
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.startExport ? 
                    <Export 
                        stateToPass={this.props.mapState} 
                        stopExport={this.stopExport.bind(this)} 
                        startExport={this.state.startExport} 
                        successUpCircle={this.props.successUpCircle}
                        upCircle={this.props.upCircle}
                        saveOfflineImport={this.props.saveImportOffline}
                        offlineImport={this.props.offlineImport}
                        ></Export> : ''}
            </Fragment>
        )
    }
}

export default SuccessUpCircle;
