import { SAVE_DRAWER, RESET_DRAWER } from "../actions/types";

export default function savedDrawer(state = [], action) {
    switch (action.type) {
        case SAVE_DRAWER:
            return action.payload;
        case RESET_DRAWER:
            return [];
        default:
    }

    return state;
}
