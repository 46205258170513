const mapState = (state = false, action) => {
    switch (action.type) {
        case "SAVE_MAP_STATE":
            return action.data;
        case "DELETE_MAP_STATE":
            return action.data;
        default:
            return state;
    }
}

export default mapState;