import { CAN_GENERATE } from "../actions/types";

export default function canGenerateReducer(state = [], action) {
    switch (action.type) {
        case CAN_GENERATE:
            return action.payload;
        default:
    }

    return state;
}
