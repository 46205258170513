import { MOVE_POI } from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';

const apiV2Url = 'https://rapport.moose-software.tech/api/v3/pois/';

export const movePoi = (data, id) => {
    const emstoken = localStorage.getItem('emstoken');
    let url = '';



    return (dispatch) => {
        return axios.put(`${apiV2Url}` + url + id, data, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(movetPoiSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};


export const movetPoiSuccess =  (data) => {
    return {
        type: MOVE_POI,
        payload: data
        
    }
};
