import React, { Component } from 'react';
import './../style/_header.scss';

import Filter from './filter';

import { FaFilter } from "react-icons/fa";
import Modal from "react-modal";
import Button from '@material-ui/core/Button';

type Props = {

};

type State = {

};

const customStyles = {
    overlay: { zIndex: 99999999 },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80%',
        overflowX: 'hidden'
    }
};

class Header extends Component<Props, State> {

    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
            resetting: false
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({modalIsOpen: true});
        this.props.filtering(true)
    }

    closeModal() {
        this.setState({modalIsOpen: false});
        this.props.filtering(false)
    }

    updateFilters(filters) {
        this.setState({
            filters: filters
        })
    }


    render() {
        return (
            <div className={'header'}>
                <FaFilter
                    color={'#d70b7d'}
                    size={'1.5em'}
                    style={{ float: 'right', padding: '2% 1% 0% 0%', cursor: 'pointer' }}
                    onClick={this.openModal}
                />
                <div className={"header-image"}></div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                    className={"filter-modal"}
                >
                    <Filter
                        filters={this.props.filters}
                        setfilters={this.props.setfilters}
                        saveFilters={this.props.saveFilters}
                        savedFilters={this.props.savedFilters}
                        updateFilters={this.updateFilters.bind(this)}
                        loader={this.props.loader}
                        poi={this.props.poi}
                        setOpenFilter={this.props.openFilter}
                        updateOpenFilter={this.props.setOpenFilter}
                        resetFilter={this.props.resetFilter}
                        getFilters={this.props.getFilters}
                    />

                    <div className={'button-position'}>
                        <Button variant="contained" color="secondary" onClick={this.closeModal}>close</Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Header;
