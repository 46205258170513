import {GET_M_RADIUS, ERROR_M_RADIUS, GET_POI} from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';

const apiV2Url = 'https://rapport.moose-software.tech/api/v3/pois/mcircles/page/0/size/10000';

export const mRadius = ( circles ) => {
    const emstoken = localStorage.getItem('emstoken');
    return (dispatch) => {
        return axios.post(`${apiV2Url}` , {circles}, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(getSuccess(response.data))
                dispatch(getPoiSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};
export const getPoiSuccess =  (data) => {
    return {
        type: GET_POI,
        payload: data

    }
};

export const getSuccess =  (data) => {
    return {
        type: GET_M_RADIUS,
        payload: data
        
    }
};


export const error =  (data) => {
    return {
        type: ERROR_M_RADIUS,
        payload: data
        
    }
};

