import {OFFCIRCLE_POI, UP_CIRCLE_SUCCESS, OFF_CIRCLE_ERROR } from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';
import successOffCircle from './successOffCircle';

const apiV2Url = 'https://rapport.moose-software.tech/api/v3/pois/offmcircles';

export const offCircle = ( params, selections ) => {
    const emstoken = localStorage.getItem('emstoken');
    //let data = {};
    let i = 0;
    const obj = {};

    for (i = 0; i <  selections.length; i++) {
        if(selections[i].pois > 0){
            const pois = Object.values(selections[i].pois)
            const name = selections[i].name
            obj[name] = pois
        }
    }

    obj.circles = params;

    return (dispatch) => {
        return axios.post(`${apiV2Url}` ,obj, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(success(response.data))
                dispatch(successOffCircle(true))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const success =  (data) => {
    return {
        type: OFFCIRCLE_POI,
        payload: data
        
    }
};

export const error =  (data) => {
    return {
        type: OFF_CIRCLE_ERROR,
        payload: data
        
    }
};

