import { UPCIRCLE_POI, ERROR_IMPORT } from '../actions/types';

export default function upCircleReducer(state = [], action) {
    switch (action.type) {
        case UPCIRCLE_POI:
            return action.payload;
        case ERROR_IMPORT:
            return action.payload;
        default:
            return state;
    }
}