import { GENERATE_XLS_ERROR, GENERATE_XLS_SUCCESS } from '../actions/types';

export default function generateXlsReducer(state = [], action) {
    switch (action.type) {
        case GENERATE_XLS_SUCCESS:
            return action.payload;
        case GENERATE_XLS_ERROR:
            return action.payload;
        default:
            return state;
    }
}
