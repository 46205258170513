import { SAVE_FILTERS } from "../actions/types";

export default function savedFilters(state = '', action) {
    switch (action.type) {
        case SAVE_FILTERS:
            return !!action.payload && action.payload;
        case "RESET_FILTER":
            return {
                reset: true
            }

        default:
    }

    return state;
}
