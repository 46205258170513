export const successOffBoundaries = (bool)=>{
    return bool ? {
        type:"OFF_BOUNDARIES_SUCCESS",
        data:bool
    }: {
        type: "RESET_BOUNDARIES_CIRCLE",
        data: bool
    }
}

export default successOffBoundaries;