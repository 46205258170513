import { UPLOAD_IMAGE, ERROR_UPLOAD } from '../actions/types';

export default function uploadImage(state = [], action) {
    switch (action.type) {
        case UPLOAD_IMAGE:
            return action.payload;
        case ERROR_UPLOAD:
            return action.payload;
        default:
            return state;
    }
}
