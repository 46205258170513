export const onerror = (bool)=>{
    return bool ? {
        type:"SHOW_ERROR",
        data:bool
    }: {
        type: "HIDE_ERROR",
        data: bool
    }
}

export default onerror;