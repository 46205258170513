import React, { Component } from 'react';
import { scaleRotate as Menu } from 'react-burger-menu'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';


import './../style/_sidebar.scss';

type Props = {

};

type State = {

};

class SidebarLegend extends Component<Props, State> {

    state = {

    }

    handleToggle = this.handleToggle.bind(this)

    componentDidUpdate(prevProps) {
        if(this.props.legend !== prevProps.legend){

        }
    }

    handleToggle(value){
        const legend = this.props.legend
        legend.forEach(function (item) {
            if(value.name === item.name){
                item.active = !item.active
            } else {
                item.active = false
            }
        })

        this.props.editLegend(value.name)
        this.props.saveLegend(legend)
        this.props.toggleLegend()
    }

    render() {
        return (
            <Menu className="sidebar-wrappen" right>
                <List>
                    {this.props.legend.length > 0 ?  this.props.legend.map((value, key, arr) => (
                            <ListItem>
                                <ListItemText primary={value.label} />
                                <Checkbox
                                    value={key}
                                    edge="end"
                                    onChange={() => this.handleToggle(value)}
                                    checked={value.active}
                                    ref="check_me"
                                    inputProps={{ 'aria-labelledby': key }}
                                />
                            </ListItem>
                        ))
                        : ''
                    }
                </List>
            </Menu>
        )
    }
}

export default SidebarLegend;
