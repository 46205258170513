import {EXPORT_POI, ERROR_EXPORT, RESET_EXPORT } from '../actions/types';

export default function postReducer(state = [], action) {
    switch (action.type) {
        case EXPORT_POI:
            return action.payload;
        case ERROR_EXPORT:
            return action.payload;
        case RESET_EXPORT:
            return true;
        default:
            return state;
    }
}
