import { GET_EXCEL_M_CIRCLES, ERROR_EXCEL_M_CIRCLES } from '../actions/types';

export default function getExcelMCircles(state = [], action) {
    switch (action.type) {
        case GET_EXCEL_M_CIRCLES:
            return action.payload;
        case ERROR_EXCEL_M_CIRCLES:
            return action.payload;
        default:
            return state;
    }
}