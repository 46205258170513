import { GET_POI, ERROR_POI, GET_FILTERS } from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';

//const apiTestUrl = 'https://rapport.moose-software.tech/api/test/pois/';
//const apiV1Url = 'https://rapport.moose-software.tech/api/v1/pois/';
const apiV2Url = 'https://rapport.moose-software.tech/api/v3/pois/';

const circleUrl = 'circle/page/0/size/100000/center/';
const polygonUrl = 'boundaries/size/100000/page/0/limits/';
const filterAllUrl = 'all/page/0/size/100000';
//const filterUrl = 'boundaries/page/1/size/1000/limits/';
const getFiltersUrl = 'filters';


export const getPoi = (coordinates, filters) => {
    const emstoken = localStorage.getItem('emstoken');
    let url = '';
    
    if(coordinates.type === 'circle'){
        url = polygonUrl + coordinates.fakePolyCoordinates
    } else {
        url = polygonUrl + coordinates
    }
    
    return (dispatch) => {
        return axios.post(`${apiV2Url}` + url , {filters}, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(getPoiSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const postFilters = ( filters ) => {
    const emstoken = localStorage.getItem('emstoken');
    return (dispatch) => {
        return axios.post(`${apiV2Url}` + filterAllUrl, {filters}, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(getPostSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const getFilters = () => {
        const token = localStorage.getItem('emstoken');
        return (dispatch) => {
            return axios.get(`${apiV2Url}` + getFiltersUrl, { headers: { emstoken: token }})
                .then(response => {
                    dispatch(getFiltersSuccess(response.data))
                })
                .catch(error => {
                    dispatch(onerror(true));
                    dispatch(loading(false));
                    throw(error);
                });
        };
};

export const getPoiSuccess =  (data) => {
    return {
        type: GET_POI,
        payload: data
        
    }
};

export const getPostSuccess =  (data) => {
    return {
        type: GET_POI,
        payload: data
        
    }
};

export const getPoiError =  (data) => {
    return {
        type: ERROR_POI,
        payload: data
        
    }
};

export const getFiltersSuccess =  (data) => {
    return {
        type: GET_FILTERS,
        payload: data
        
    }
};

