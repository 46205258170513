export const saveMapState = (bool)=>{
    return bool ? {
        type:"SAVE_MAP_STATE",
        data:bool
    }: {
        type: "DELETE_MAP_STATE",
        data: bool
    }
}

export default saveMapState;