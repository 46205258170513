const successUpCircle = (state = false, action) => {
    switch (action.type) {
        case "OFF_CIRCLE_SUCCESS":
            return action.data;
        case "RESET_OFF_CIRCLE":
            return action.data;
        default:
            return state;
    }
}

export default successUpCircle;