import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import toohmaps from "./redux/reducers";

import App from './App';
import {applyMiddleware, createStore} from 'redux'

import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'

import crossBrowserListener from './utils/reduxpersist-listener'
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";

const persistConfig = {
      key: 'root',
      whitelist: ['none'],
      blacklist: ['mapReducer'],
      storage,
}

const persistedReducer = persistReducer(persistConfig, toohmaps)

const actionSanitizer = (action) => (
    action.type === 'FILE_DOWNLOAD_SUCCESS' && action.data ?
        { ...action, data: '<<LONG_BLOB>>' } : action
);

const stateSanitizer = (state) => state.data ? { ...state, data: '<<LONG_BLOB>>' } : state

const composeEnhancers = composeWithDevTools({
    actionSanitizer,
    stateSanitizer,
    serialize: true
});

    const store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(thunk)),
     )
    const persistor = persistStore(store)

    window.addEventListener('storage', crossBrowserListener(store, persistConfig));


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>, document.getElementById('root'));

serviceWorker.unregister();
