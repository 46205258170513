import { IMPORT_POI, ERROR_IMPORT } from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';

const apiV2Url = 'https://rapport.moose-software.tech/api/v3/upload-points';

export const importPoi = ( body ) => {
    const emstoken = localStorage.getItem('emstoken');
    return (dispatch) => {
        return axios.post(`${apiV2Url}` , body, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(importSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const importSuccess =  (data) => {
    return {
        type: IMPORT_POI,
        payload: data
        
    }
};


export const errorImport =  (data) => {
    return {
        type: ERROR_IMPORT,
        payload: data
        
    }
};

