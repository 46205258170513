import { EXPORT_POI, ERROR_EXPORT, GENERATE_XLS_ERROR, GENERATE_XLS_SUCCESS, FETCHING_EXPORT, RESET_EXPORT } from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';

const apiV2Url = 'https://rapport.moose-software.tech/api/v3/selections/export';


export const exportPoi = ( selections, uniqueid, hasNext ) => {
    const emstoken = localStorage.getItem('emstoken');
    const data = {
        uniqid : uniqueid,
        selections: selections
    }

    return (dispatch) => {
        return axios.post(`${apiV2Url}` , data, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(exportSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const generateXls = ( uniqid ) => {
    const emstoken = localStorage.getItem('emstoken');
    return (dispatch) => {
        return axios.get(`${apiV2Url}` + '/' + uniqid , { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(generateSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const exportSuccess =  (data) => {
    return {
        type: EXPORT_POI,
        payload: data
        
    }
};

export const exportFetching =  (data) => {
    return {
        type: FETCHING_EXPORT,
        payload: data

    }
};

export const errorExport =  (data) => {
    return {
        type: ERROR_EXPORT,
        payload: data
        
    }
};

export const generateSuccess =  (data) => {
    return {
        type: GENERATE_XLS_SUCCESS,
        payload: data

    }
};


export const generateError =  (data) => {
    return {
        type: GENERATE_XLS_ERROR,
        payload: data

    }
};

export function resetExport(payload) {
    return {
        type: RESET_EXPORT,
        payload: payload
    };
}



