import React from 'react';
import './App.css';
import MapContainer from './container/mapContainer';
import PostMapContainer from './container/postMapContainer';
import GuestMapContainer from './container/guestMapContainer';
import Export from './container/export';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
    const currentURL = window.location.href;
    const splittedToken = currentURL.split("?");
    let splitted = []
    if(splittedToken.length > 1){
        splitted = splittedToken[splittedToken.length-2].split("/");
    } else {
        splitted = currentURL.split('/')
    }
    const param = splitted[splitted.length -1]
    const path = splitted[splitted.length -2]
    //const token = splittedToken[splittedToken.length-1].replace('access_token=', '')
    //localStorage.setItem('emstoken', token)
    let postPath = '/post'
    let getPath = '/getselection'
    let guestPath = '/guest'
    if(path === 'getselection'){
        const getSelection ="/getselection/:id"
        getPath = getSelection.replace(':id', param);
    } else if(path === 'post') {
        const postSelection ="/post/:id"
        postPath = postSelection.replace(':id', param);
    } else if(path === 'guest') {
        const guestSelection ="/guest/:id"
        guestPath = guestSelection.replace(':id', param);
    }
   
  return (
      <Router>
          <Switch>
              <Route exact path="/" component={MapContainer} />
              <Route exact path="/selection" component={MapContainer} />
              <Route path={getPath} exact component={MapContainer} />
              <Route path={'/waiting-export'} component={Export} />
              <Route exact path={postPath} component={PostMapContainer} />
              <Route exact path="/post" component={PostMapContainer} />
              <Route exact path={guestPath} component={GuestMapContainer} />
          </Switch>
      </Router>
  );
}

export default App;
