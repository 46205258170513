import React, { Component, Fragment, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import ScrollUpButton from "react-scroll-up-button";
import axios from 'axios'

import Sidebar from './../components/sidebar';
import Header from './../components/header';
import Loader from './../components/loader';
import Error from './../components/error';

import { getPoi, getFilters, postFilters } from './../redux/actions/getPoi';
import { saveMap, updateMap, getSelection, createPostMap, resetMap } from './../redux/actions/getMap';
import { exportPoi, generateXls, resetExport} from './../redux/actions/exportPoi';
import { importPoi } from './../redux/actions/importPoi';
import { upCircle } from './../redux/actions/upCircle';
import { saveFilters, resetFilter } from './../redux/actions/saveFilter';
import { saveDrawer, resetDrawer } from './../redux/actions/saveDrawer';
import { saveDrawerOffline, resetDrawerOffline } from './../redux/actions/saveDrawerOffline';
import { saveImportOffline, resetImportOffline } from './../redux/actions/saveImportOffline';
import { mRadius } from '../redux/actions/mRadius';
import {canGenerate} from "../redux/actions/canGenerate";
import { loading } from './../redux/actions/loader';
import { onerror } from './../redux/actions/error';
import { filtering } from './../redux/actions/filtering';
import {movePoi} from "../redux/actions/movePoi";
import successUpCircle from '../redux/actions/successUpCircle';
import successOffCircle from '../redux/actions/successOffCircle';
import successOffBoundaries from '../redux/actions/successOffBoundaries';
import saveMapState from '../redux/actions/saveMapState';

import {excelMCircles}  from '../redux/actions/excelMCircles';

import './../style/_main.scss';
import { DateRange } from '@material-ui/icons';
import SuccessUpCircle from '../components/successUpCircle';
import SuccessOffCircle from '../components/successOffCircle';
import SuccessOffBoundaries from '../components/successOffBoundaries';


const Maps = lazy(() => import('./../components/mapPre'));


class MapContainer extends Component {

    state = {
        poi: [],
        openFilter: false,
        hasError: false
    }

    componentWillMount() {
        const self = this
        axios.interceptors.request.use(function (config) {
            // spinning start to show
            self.props.loading(true)
            return config
        }, function (error) {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(function (response) {
            // spinning hide
            self.props.loading(false)

            return response;
        }, function (error) {
            return Promise.reject(error);
        });
    }

    componentDidMount() {
        const currentURL = window.location.href;
        const splittedToken = currentURL.split("?");
        const token = splittedToken[splittedToken.length-1].replace('access_token=', '')
        localStorage.setItem('emstoken', token)
        this.props.getFilters();
    }

    componentDidUpdate(prevProps) {
        if (this.props.poi !== prevProps.poi) {
            /*let poi = this.props.poi[0].data
            if(this.props.poi.length > 1){
                const plength = this.props.poi.length -1
                const data = this.props.poi[plength].data
                Object.assign(poi, data);
            }*/

            /*this.setState({
                poi: poi
            })*/
        }

        if (this.props.drawer !== prevProps.drawer) {
            this.setState({
                hasDrawing: true
            })
        }
    }

    updatePoi() {
        this.setState({ newpoi: true })
    }

    updateFilters(data) {
        this.props.saveFilters(data);
    }

    updateDrawer(data) {
        this.props.saveDrawer(data);
        this.props.saveDrawer.bind(this)
        this.setState({
            havedrawing: true
        })
    }

    updateOpenFilter(data) {
        this.setState({ openFilter: data });
    }

    render() {
        const poi = this.props.poi
        return (
            <Fragment>
                <Header
                    filters={this.props.filters}
                    setfilters={this.props.postFilters}
                    saveFilters={this.props.saveFilters.bind(this)}
                    savedFilters={this.props.savedFilters}
                    filtering={this.props.filtering}
                    filter={this.props.filter}
                    loader={this.props.loader}
                    poi={poi}
                    openFilter={this.state.openFilter}
                    setOpenFilter={this.updateOpenFilter.bind(this)}
                    resetFilter={this.props.resetFilter}
                    getFilters = {this.props.getFilters}
                />
                <Sidebar
                    drawer={this.props.drawer}
                    saveDrawer={this.updateDrawer.bind(this)}
                    exportPoi={this.props.exportPoi}
                />
                <Suspense fallback={<div></div>}>
                    {this.props.error ? <Error onerror={this.props.onerror} warnings={this.props.imported.warnings}/> : null}
                    {this.props.successUpCircle ? 
                        <SuccessUpCircle 
                            resetUpCircle={this.props.resetUpCircle} 
                            mapState={this.props.mapState} 
                            successUpCircle={this.props.successUpCircle}
                            upCircle={this.props.upCircled}
                            saveImportOffline={this.props.saveImportOffline}
                            offlineImport={this.props.offlineImport}
                            /> : null}
                    {this.props.successOffCircle ? 
                        <SuccessOffCircle 
                            resetOffCircle={this.props.resetOffCircle}
                            mapState={this.props.mapState} 
                            successOffCircle={this.props.successOffCircle}
                            offCircled={this.props.offCircled}
                            offBoundariezed={this.props.offBoundariezed}
                            resetExport={this.props.resetExport}
                            saveImportOffline={this.props.saveImportOffline}
                            offlineImport={this.props.offlineImport}
                            /> : null}
                    {this.props.successOffBoundaries ? 
                        <SuccessOffBoundaries 
                            resetOffBoundaries={this.props.resetOffBoundaries}
                            mapState={this.props.mapState} 
                            successOffBoundaries={this.props.successOffBoundaries}
                            offBoundariezed={this.props.offBoundariezed}
                            resetExport={this.props.resetExport}
                            saveImportOffline={this.props.saveImportOffline}
                            offlineImport={this.props.offlineImport}
                            /> : null}        
                    {this.props.loader ? <Loader /> : null}
                    <Maps
                        poi={poi}
                        getPoi={this.props.getPoi}
                        getSelection={this.props.getSelection}
                        getFilters={this.props.getFilters}
                        updatePoi={this.updatePoi.bind(this)}
                        savedFilters={this.props.savedFilters}
                        saveDrawer={this.updateDrawer.bind(this)}
                        resetDrawer={this.props.resetDrawer}
                        saveDrawerOffline={this.props.saveDrawerOffline}
                        resetDrawerOffline={this.props.resetDrawerOffline}
                        saveMap={this.props.saveMap}
                        map={this.props.map}
                        updatemap={this.props.updateMap}
                        exportPoi={this.props.exportPoi}
                        generateXls={this.props.generateXls}
                        generatedXls={this.props.generatedXls}
                        importPoi={this.props.importPoi}
                        exported={this.props.exported}
                        imported={this.props.imported}
                        mRadius={this.props.mRadius}
                        mRadiusPoi={this.props.mRadiusPoi}
                        savePostMap={this.props.createPostMap}
                        filter={this.props.filter}
                        openFilter={this.state.openFilter}
                        setOpenFilter={this.updateOpenFilter.bind(this)}
                        canGenerateRed={this.props.canGenerateRed}
                        canGenerate={this.props.canGenerate}
                        drawer={this.props.drawer}
                        mapPost={this.props.mapPost}
                        resetMap={this.props.resetMap}
                        movePoi={this.props.movePoi}
                        resetFilter={this.props.resetFilter}
                        upCircle={this.props.upCircle}
                        saveMapState={this.props.saveMapState}
                        onerror={this.props.onerror}
                        drawerOffline={this.props.drawerOffline}
                        excelMCircles={this.props.excelMCircles}
                    />
                </Suspense>
                <ScrollUpButton ShowAtPosition={15} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        poi: state.poi,
        loader: state.loader,
        filter: state.filter,
        filters: state.filters,
        savedFilters: state.savedFilters,
        drawer: state.drawer,
        drawerOffline: state.drawerOffline,
        map: state.map,
        exported: state.exported,
        generatedXls: state.generatedXls,
        imported: state.imported,
        mRadiusPoi: state.mRadius,
        canGenerateRed: state.canGenerateRed,
        mapPost: state.mapPost,
        movedPoi: state.movedPoi,
        error: state.error,
        successUpCircle: state.successUpCircle,
        successOffCircle: state.successOffCircle,
        successOffBoundaries: state.successOffBoundaries,
        mapState: state.mapState,
        upCircled: state.upCircle,
        offCircled: state.offCircle,
        offBoundariezed: state.offBoundaries,
        offlineImport: state.offlineImport,
        excelMCircles: state.excelMCircles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getPoi: (coordinates, filters) => {
            dispatch(getPoi(coordinates, filters));
        },
        movePoi: (data, id) => {
            dispatch(movePoi(data, id));
        },
        importPoi: (data) => {
            dispatch(importPoi(data));
        },
        upCircle: (data) => {
            dispatch(upCircle(data));
        },
        excelMCircles: (data) => {
            dispatch(excelMCircles(data));
        },
        exportPoi: (data, uniqid, hasNext) => {
            dispatch(exportPoi(data, uniqid, hasNext));
        },
        generateXls: (uniqid) => {
            dispatch(generateXls(uniqid));
        },
        postFilters: (filters) => {
            dispatch(postFilters(filters));
        },
        loading: load => {
            dispatch(loading(load));
        },
        onerror: data => {
            dispatch(onerror(data));
        },
        filtering: filter => {
            dispatch(filtering(filter));
        },
        saveFilters: data => {
            dispatch(saveFilters(data));
        },
        saveDrawer: data => {
            dispatch(saveDrawer(data));
        },
        saveDrawerOffline: data => {
            dispatch(saveDrawerOffline(data));
        },
        saveImportOffline: data => {
            dispatch(saveImportOffline(data));
        },
        resetImportOffline: data => {
            dispatch(resetImportOffline(data));
        },
        resetDrawer: data => {
            dispatch(resetDrawer(data));
        },
        resetDrawerOffline: data => {
            dispatch(resetDrawerOffline(data));
        },
        resetFilter: data => {
            dispatch(resetFilter(data));
        },
        saveMap: (data) => {
            dispatch(saveMap(data));
        },
        resetMap: (data) => {
            dispatch(resetMap(data));
        },
        updateMap: (id, data) => {
            dispatch(updateMap(id, data));
        },
        getSelection: (id) => {
            dispatch(getSelection(id));
        },
        mRadius: (circle) => {
            dispatch(mRadius(circle));
        },
        createPostMap: (data) => {
            dispatch(createPostMap(data));
        },
        canGenerate: (data) => {
            dispatch(canGenerate(data));
        },
        getFilters: () => {
            dispatch(getFilters());
        },
        resetUpCircle: (data) => {
            dispatch(successUpCircle(data));
        },
        resetOffCircle: (data) => {
            dispatch(successOffCircle(data));
        },
        resetOffBoundaries: (data) => {
            dispatch(successOffBoundaries(data));
        },
        resetExport: (data) => {
            dispatch(resetExport(data));
        },
        saveMapState: (data) => {
            dispatch(saveMapState(data));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MapContainer);

