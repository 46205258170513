import {SAVE_DRAWER_OFFLINE, RESET_DRAWER_OFFLINE} from "./types";

export function saveDrawerOffline(payload) {
    return {
        type: SAVE_DRAWER_OFFLINE,
        payload: payload
    };
}

export function resetDrawerOffline(payload) {
    return {
        type: RESET_DRAWER_OFFLINE,
        payload: payload
    };
}
