import { EDITING_LEGEND } from "../actions/types";

export default function editedLegend(state = [], action) {
    switch (action.type) {
        case EDITING_LEGEND:
            return action.payload;
        default:
    }

    return state;
}