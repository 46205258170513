import {UPCIRCLE_POI, UP_CIRCLE_SUCCESS, UP_CIRCLE_ERROR } from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';
import successUpCircle from './successUpCircle';

const apiV2Url = 'https://rapport.moose-software.tech/api/v3/pois/upcircle/size/10000/page/0/center/';

export const upCircle = ( params ) => {
    const emstoken = localStorage.getItem('emstoken');
    const x = params.x;
    const y = params.y;
    const radius = params.radius;
    const data =  {
        filename: params.filename,
        lat: x,
        lon: y,
    };
    return (dispatch) => {
        return axios.post(`${apiV2Url}` + x + ',' + y + '/radius/' + radius ,data, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(success(response.data))
                dispatch(successUpCircle(true))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const success =  (data) => {
    return {
        type: UPCIRCLE_POI,
        payload: data
        
    }
};

export const error =  (data) => {
    return {
        type: UP_CIRCLE_ERROR,
        payload: data
        
    }
};

