// This will can combine one or more Reducer functions and export it through Redux's combineReducer helper.
import { combineReducers } from "redux";

import poi from './poiReducer';
import filters from './filterReducer';
import loader from './loader';
import error from './error';
import filterModal from './filterModal';
import savedFilters from './filtersReducer';
import savedDrawer from './drawerReducer';
import savedLegend from './legendReducer';
import editedLegend from './editingLegendReducer';
import mapReducer from './mapReducer';
import mapPostReducer from './mapPostReducer';
import exportReducer from './exportReducer';
import generateXlsReducer from './generateXlsReducer';
import importReducer from './importReducer';
import getMRadius from './mRadiusReducer';
import filterPost from './filterPostReducer';
import canGenerateReducer from "./canGenerateReducer";
import uploadImageReducer from "./uploadImageReducer";
import movePoiReducer from "./movePoiReducer";
import upCircleReducer from "./upcircleReducer";
import offCircleReducer from "./offcircleReducer";
import offBoundariesReducer from "./offboundariesReducer";
import successUpCircle from "./successUpCircle";
import successOffCircle from "./successOffCircle";
import successOffBoundaries from "./successOffBoundaries";
import mapState from "./mapStateReducer";
import savedDrawerOffline from "./drawerOfflineReducer";
import savedOfflineImport from "./offlineImportReducer";
import getExcelMCircles from "./excelCirclesReducer";

const toohmaps = combineReducers({
    poi: poi,
    loader: loader,
    filter: filterModal,
    filters: filters,
    savedFilters: savedFilters,
    drawer: savedDrawer,
    drawerOffline: savedDrawerOffline,
    legend: savedLegend,
    map: mapReducer,
    mapPost: mapPostReducer,
    exported: exportReducer,
    imported: importReducer,
    mRadius: getMRadius,
    filtersPost: filterPost,
    editedLegend: editedLegend,
    generatedXls: generateXlsReducer,
    canGenerateRed: canGenerateReducer,
    imageLegend: uploadImageReducer,
    movedPoi: movePoiReducer,
    upCircle: upCircleReducer,
    offCircle: offCircleReducer,
    offBoundaries: offBoundariesReducer,
    error: error,
    successUpCircle: successUpCircle,
    successOffCircle: successOffCircle,
    successOffBoundaries: successOffBoundaries,
    offlineImport: savedOfflineImport,
    mapState: mapState,
    getExcelMCircles: getExcelMCircles

});

export default toohmaps;

// Example for combining multiple reducers:
// export default combineReducers({ count, secondCounter });
