import { UPLOAD_IMAGE, ERROR_UPLOAD } from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';

const apiV2Url = 'https://rapport.moose-software.tech/api/v3';

export const uploadImage = ( format, img ) => {
    const emstoken = localStorage.getItem('emstoken');
    return (dispatch) => {
        return axios.post(`${apiV2Url}/upload-legend-image/` + format , {img}, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(getSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const getSuccess =  (data) => {
    return {
        type: UPLOAD_IMAGE,
        payload: data
        
    }
};


export const error =  (data) => {
    return {
        type: ERROR_UPLOAD,
        payload: data
        
    }
};

