import { GET_M_RADIUS, ERROR_M_RADIUS } from '../actions/types';

export default function getMRadius(state = [], action) {
    switch (action.type) {
        case GET_M_RADIUS:
            return action.payload;
        case ERROR_M_RADIUS:
            return action.payload;
        default:
            return state;
    }
}