import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Export from './../container/export';

import './../style/_loader.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SuccessOffCircle extends Component<Props, State> {

    state = {
        openDialog: true,
        startExport: false
    }

    goToExport(){
        this.setState({
            startExport: true
        })
    }

    stopExport(){
        this.setState({
            startExport: false
         })

         this.props.resetOffCircle(false);
    }

    closeDialog() {
        this.setState({
            openDialog: false,
            startExport: true
        })
    }

    closeDialogError() {
        this.setState({
            openDialog: false,
        })
        this.props.resetOffCircle(false);
        this.props.resetExport();
    }

    render() {
        return (
            <Fragment>
                {Object.values(this.props.offBoundariezed.data['_global_']).length > 0 ?
                <Dialog
                    open={this.state.openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.closeDialog.bind(this)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Sei in modalità offline, vuoi scaricare gli elementi selezionati?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.closeDialog.bind(this)} variant="contained" color="secondary">
                            Esporta
                        </Button>
                    </DialogActions>
                </Dialog> :                 
                <Dialog
                    open={this.state.openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.closeDialogError.bind(this)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Nessun elemento trovato"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.closeDialogError.bind(this)} variant="contained" color="secondary">
                            Chiudi
                        </Button>
                    </DialogActions>
                </Dialog>}
                {this.state.startExport ? 
                    <Export 
                        stateToPass={this.props.mapState} 
                        stopExport={this.stopExport.bind(this)} 
                        startExport={this.state.startExport} 
                        successOffCircle={this.props.successOffCircle}
                        offCircled={this.props.offCircled}
                        saveOfflineImport={this.props.saveImportOffline}
                        offlineImport={this.props.offlineImport}
                    ></Export> : ''}
            </Fragment>
        )
    }
}

export default SuccessOffCircle;
