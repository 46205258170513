import { GET_FILTERS, ERROR_FILTERS, FETCH_FILTERS } from '../actions/types';

export default function postReducer(state = [], action) {
    switch (action.type) {
        case GET_FILTERS:
            return [...state, action.payload];
        case ERROR_FILTERS:
            return state.filter(post => post._id !== action.payload.id);
        case FETCH_FILTERS:
            return action.posts;
        default:
            return state;
    }
}