import { SAVE_LEGEND } from "../actions/types";

export default function savedLegend(state = [], action) {
    switch (action.type) {
        case SAVE_LEGEND:
            return action.payload;
        default:
    }

    return state;
}