import React, { Component, Fragment, lazy, Suspense  } from 'react';
import { connect } from 'react-redux';
import ScrollUpButton from "react-scroll-up-button";
import axios from 'axios'
import Loader from './../components/loader';

import { getPoi, getFilters, postFilters } from './../redux/actions/getPoi';
import { createPostMap, updateMapPost, getGuestMap } from './../redux/actions/getMap';
import { exportPoi } from './../redux/actions/exportPoi';
import { importPoi } from './../redux/actions/importPoi';
import { saveFilters } from './../redux/actions/saveFilter';
import { saveDrawer } from './../redux/actions/saveDrawer';
import { saveLegend } from './../redux/actions/saveLegend';
import { editingLegend } from './../redux/actions/editingLegend';
import { mRadius } from '../redux/actions/mRadius';

import { loading } from './../redux/actions/loader';

import './../style/_main.scss';

const GuestMap = lazy(() => import('../components/mapGuest'));

class GuestMapContainer extends Component {
    
    state = {
        poi: [],
        updateLegend: false
    }
    
    componentWillMount(){
        const self = this
        axios.interceptors.request.use(function (config) {
            // spinning start to show
            self.props.loading(true)
            return config
        }, function (error) {
            return Promise.reject(error);
        });
        
        axios.interceptors.response.use(function (response) {
            // spinning hide
            self.props.loading(false)
            
            return response;
        }, function (error) {
            self.props.loading(false)
            return Promise.reject(error);
        });
    }
    
    componentDidMount(){
        //this.props.getFilters();
    }
    
    componentDidUpdate(prevProps){
        if(this.props.poi !== prevProps.poi){
            /*let poi = this.props.poi[0].data
            if(this.props.poi.length > 1){
                const plength = this.props.poi.length -1
                const data = this.props.poi[plength].data
                Object.assign(poi, data);
            }*/

            /*this.setState({
                poi: poi
            })*/
        }
        
        if(this.props.drawer !== prevProps.drawer){
            this.setState({
                hasDrawing: true
            })
        }

        if(this.props.legend !== prevProps.legend){
            this.setState({
                updateLegend: true
            })
        }

    }
    
    updatePoi(){
        this.setState({newpoi: true})
    }
    
    updateFilters(data){
        this.props.saveFilters(data);
    }
    
    updateDrawer(data){
        this.props.saveDrawer(data);
        this.props.saveDrawer.bind(this)
        this.setState({
            havedrawing: true
        })
    }

    updateLegend(data){
        this.props.saveLegend(data);
        this.setState({
            updateLegend: true,
            legendData: data
        })
    }

    toggleLegend(){
        this.setState({
            updateLegend: !this.state.updateLegend,
        })
    }

    editLegend(data){
        this.props.editingLegend(data);
    }

    render() {
        const poi = this.props.poi
        return (
            <Fragment>
                <Suspense fallback={<div></div>}>
                    { this.props.loader ? <Loader/> : null }
                    <GuestMap
                        poi={poi}
                        getPoi={this.props.getPoi}
                        getSelection={this.props.getSelection}
                        updatePoi={this.updatePoi.bind(this)}
                        savedFilters={this.props.savedFilters}
                        saveDrawer={this.updateDrawer.bind(this)}
                        saveMap={this.props.createPostMap}
                        getSelection={this.props.getMapSelection}
                        mapPost={this.props.mapPost}
                        update={this.props.updateMapPost}
                        map={this.props.map}
                        updatemap={this.props.updateMap}
                        exportPoi={this.props.exportPoi}
                        importPoi={this.props.importPoi}
                        exported={this.props.exported}
                        imported={this.props.imported}
                        mRadius={this.props.mRadius}
                        mRadiusPoi={this.props.mRadiusPoi}
                        filtersPost={this.props.filtersPost}
                        saveLegend={this.updateLegend.bind(this)}
                        legend={this.state.legendData}
                        toggleLegend={this.state.updateLegend}
                        editedLegend={this.props.editedLegend}
                        editLegend={this.editLegend.bind(this)}
                        ref={el => (this.componentRef = el)}
                    />
                </Suspense>
                <ScrollUpButton ShowAtPosition={15} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        poi: state.poi,
        loader: state.loader,
        filters: state.filters,
        savedFilters: state.savedFilters,
        drawer: state.drawer,
        legend: state.legend,
        editedLegend: state.editedLegend,
        mapPost: state.mapPost,
        exported: state.exported,
        imported: state.imported,
        mRadiusPoi: state.mRadius,
        filtersPost: state.filtersPost
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getPoi: (coordinates, filters) => {
            dispatch(getPoi(coordinates, filters));
        },
        getFilters: () => {
            dispatch(getFilters());
        },
        importPoi: (data) => {
            dispatch(importPoi(data));
        },
        exportPoi: (data) => {
            dispatch(exportPoi(data));
        },
        postFilters: (filters) => {
            dispatch(postFilters(filters));
        },
        loading: load => {
            dispatch(loading(load));
        },
        saveFilters: data => {
            dispatch(saveFilters(data));
        },
        saveDrawer: data => {
            dispatch(saveDrawer(data));
        },
        saveLegend: data => {
            dispatch(saveLegend(data));
        },
        editingLegend: data => {
            dispatch(editingLegend(data));
        },
        createPostMap: (data) => {
            dispatch(createPostMap(data));
        },
        updateMapPost: (id, data, canDownload) => {
            dispatch(updateMapPost(id, data, canDownload));
        },
        getMapSelection: (id) => {
            dispatch(getGuestMap(id));
        },
        mRadius: (circle) => {
            dispatch(mRadius(circle));
        },
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GuestMapContainer);

