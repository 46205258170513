import { GET_FILTERS_POST } from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';

//const apiTestUrl = 'https://rapport.moose-software.tech/api/test/pois/';
//const apiV1Url = 'https://rapport.moose-software.tech/api/v1/pois/';
const apiV2Url = 'https://rapport.moose-software.tech/api/v3/maps/filtersforlegenda/';


export const getFiltersPost = (idMap) => {
        const emstoken = localStorage.getItem('emstoken');
        return (dispatch) => {
        return axios.get(`${apiV2Url}` + idMap, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(getFiltersPostSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const getFiltersPostSuccess =  (data) => {
    return {
        type: GET_FILTERS_POST,
        payload: data
        
    }
};




