import {GET_POI, ERROR_POI, FETCH_POI, UPDATE_POI, RESET_MAP_PRE} from '../actions/types';

export default function postReducer(state = [], action) {
    switch (action.type) {
        case GET_POI:
            return [...state, action.payload];
        case ERROR_POI:
            return state.filter(post => post._id !== action.payload.id);
        case FETCH_POI:
            return action.posts;
        case UPDATE_POI:
            return action.payload;
        case RESET_MAP_PRE:
            return []
        default:
            return state;
    }
}
