import { GET_FILTERS_POST, FETCH_FILTERS_POST } from '../actions/types';

export default function filterPostReducer(state = [], action) {
    switch (action.type) {
        case GET_FILTERS_POST:
            return [...state, action.payload];
        case FETCH_FILTERS_POST:
            return action.posts;
        default:
            return state;
    }
}
