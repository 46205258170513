import {SAVE_OFFLINE_IMPORT, RESET_OFFLINE_IMPORT} from "./types";

export function saveImportOffline(payload) {
    return {
        type: SAVE_OFFLINE_IMPORT,
        payload: payload
    };
}

export function resetImportOffline(payload) {
    return {
        type: RESET_OFFLINE_IMPORT,
        payload: payload
    };
}
