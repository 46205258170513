import { SAVE_OFFLINE_IMPORT, RESET_OFFLINE_IMPORT } from "../actions/types";

export default function savedOfflineImport(state = [], action) {
    switch (action.type) {
        case SAVE_OFFLINE_IMPORT:
            return action.payload;
        case RESET_OFFLINE_IMPORT:
            return [];
        default:
    }

    return state;
}
