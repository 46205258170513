import React, { Component } from 'react';
import { scaleRotate as Menu } from 'react-burger-menu'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
//import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import './../style/_sidebar.scss';

type Props = {

};

type State = {
    drawed: [],
    saveExportPoi: []
};

class Sidebar extends Component<Props, State> {

    state = {
        drawed: [],
        saveDrawer: [],
        saveExportPoi: [],
        checkBoxAllSelection: false
    }

    componentDidMount() { }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.drawer !== this.props.drawer) {
            this.setState({ drawed: this.props.drawer });
        }
    }

    showSettings(event) {
        event.preventDefault();
    }

    handleToggle(event, selected) {
        const copyDrawed = this.state.drawed;
        const obj = {
            visibility: {}
        };

        obj.pois = copyDrawed[event.target.value].pois;
        obj.pois.name = copyDrawed[event.target.value].pois.name;
        obj.radius = copyDrawed[event.target.value].radius;
        obj.type = copyDrawed[event.target.value].type;
        obj.lat = copyDrawed[event.target.value].lat;
        obj.lng = copyDrawed[event.target.value].lng;
        obj.selected = selected;
        obj.visibility.visible = !copyDrawed[event.target.value].visibility.visible;
        obj.visibility.name = copyDrawed[event.target.value].visibility.name;

        copyDrawed[event.target.value] = obj;

        this.saveDrawedAndExportPoi(copyDrawed);
    }

    handleToggleAllSelection() {
        const copyDrawed = this.state.drawed;

        if (!this.state.checkBoxAllSelection) {
            let drawedCheckAll = copyDrawed.map((drawed, index, self) => {
                drawed.selected = true;

                return self.reduce(resp => !!resp && resp)
            }).filter(element => element != null);

            this.saveDrawedAndExportPoi(drawedCheckAll);
        } else {
            let drawedResetAll = copyDrawed.map((drawed, index, self) => {
                drawed.selected = false;

                return self.reduce(resp => !!resp && resp)
            }).filter(element => element != null);

            this.saveDrawedAndExportPoi(drawedResetAll);
        }

        this.setState({
            checkBoxAllSelection: !this.state.checkBoxAllSelection
        });
    }

    exportSelection(value) {
        const pois = [];

        let selections = {};
        selections.name = value.pois.name;
        selections.markers = value.pois;

        pois.push(selections);

        this.props.exportPoi(pois);
    }

    exportAllSelection() {
        let drawedSelected = this.state.drawed.map(drawed => {
            if (drawed.selected) {
                return {
                    name: drawed.pois.name,
                    markers: drawed.pois
                }
            }
        }).filter(element => element != null);

        this.setState({
            saveExportPoi: drawedSelected
        });

        this.props.exportPoi(drawedSelected);
    }

    saveDrawedAndExportPoi(drawedResult) {
        this.setState({ drawed: drawedResult }, () => {
            let drawedSelected = this.state.drawed.map(drawed => {
                if (drawed.selected) {
                    return {
                        name: drawed.pois.name,
                        markers: drawed.pois
                    }
                }
            }).filter(element => element != null);

            this.setState({
                saveExportPoi: drawedSelected
            });
        });

        this.updateDrawer(this.state.drawed);
    }

    hiddenSelection(value, key) {
        const copyDrawed = this.state.drawed;

        const obj = {
            visibility: {}
        };

        obj.pois = copyDrawed[key].pois;
        obj.pois.name = copyDrawed[key].pois.name;
        obj.radius = copyDrawed[key].radius;
        obj.type = copyDrawed[key].type;
        obj.lat = copyDrawed[key].lat;
        obj.lng = copyDrawed[key].lng;
        obj.selected = !copyDrawed[key].visibility.visible ? copyDrawed[key].selected : false;
        obj.visibility.visible = !copyDrawed[key].visibility.visible;
        obj.visibility.name = copyDrawed[key].visibility.name === 'nascondi' ? 'visibile' : 'nascondi';

        copyDrawed[key] = obj;

        this.setState({ drawed: copyDrawed }, () => {
            this.updateDrawer(this.state.drawed);
        });
    }

    updateDrawer(data) {
        this.props.saveDrawer(data);
        this.props.saveDrawer.bind(this);

        let saveExportPoifilterDrawed = this.state.drawed.filter(drawed => drawed.selected);
        this.setState({ saveExportPoi: saveExportPoifilterDrawed });
    }

    cancelSingleSelection(value) {
        let removedDrawed = this.state.drawed.filter(drawed => drawed !== value);

        this.setState({ drawed: removedDrawed }, () => {
            let drawedSelected = this.state.drawed.map(drawed => {
                if (drawed.selected) {
                    return {
                        name: drawed.pois.name,
                        markers: drawed.pois
                    }
                }
            }).filter(element => element != null);

            this.setState({
                saveExportPoi: drawedSelected
            });
        });

        this.updateDrawer(removedDrawed);
    }

    cancelAllSelection() {
        this.setState({ drawed: [] });
        this.updateDrawer([]);
        this.setState({ saveExportPoi: [] });
    }

    render() {
        return (
            <Menu className="sidebar-wrappen" right>
                <div className="checkbox-wrappen">
                    {(this.props.drawer.length > 0) ? <Checkbox
                        value="all-selected"
                        edge="end"
                        onChange={this.handleToggleAllSelection.bind(this)}
                        checked={this.state.checkBoxAllSelection.selected}
                        inputProps={{ 'aria-labelledby': 'all-selected' }}
                    /> : null}
                </div>

                <List className="list-wrappen">
                    {this.props.drawer.length > 0 ? this.props.drawer.map((value, key, arr) => (
                        <ListItem key={key} button>
                            <ExpansionPanel className="expansion-panel-wrappen" defaultExpanded>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    {/* <ListItemText id={key} primary={`Selezione ${value.pois.name}`} /> */}
                                    <div className="section-title">Selezione <span>{`${value.pois.name ? value.pois.name : 'selezione importata'}`}</span></div>
                                </ExpansionPanelSummary>

                                <ExpansionPanelDetails>
                                    {value.lat ?
                                        <div className="label-align">
                                            <span className="label">Latitudine:</span>
                                            <span className="value">{`${value.lat}`}</span>
                                        </div>
                                    : ''}
                                    {value.lng ?
                                        <div className="label-align">
                                            <span className="label">Longitudine:</span>
                                            <span className="value">{`${value.lng}`}</span>
                                        </div>
                                    : ''}
                                    {value.radius ?
                                    <div className="label-align">
                                        <span className="label">Raggio:</span>
                                        <span className="value">{`${value.radius}`}</span>
                                    </div>
                                    : ''}
                                    <div className="label-align">
                                        <span className="label">Numero punti:</span>
                                        <span className="value">{`${Object.keys(value.pois).length - 1}`}</span>
                                    </div>

                                    <div className="label-align colum-direction">
                                        <span className="label">Lista attività:</span>
                                        <div className="list-points">
                                            {Object.keys(value.pois).map((point, key, arr) => (
                                                <div className="point">
                                                    <div className="property">
                                                        <span className="label">Regione:</span>
                                                        <span className="value">{value.pois[point].regione}</span>
                                                    </div>

                                                    <div className="property">
                                                        <span className="label">Città:</span>
                                                        <span className="value">{value.pois[point].citta}</span>
                                                    </div>

                                                    <div className="property">
                                                        <span className="label">Provincia:</span>
                                                        <span className="value">{value.pois[point].provincia}</span>
                                                    </div>

                                                    <div className="property">
                                                        <span className="label">Società:</span>
                                                        <span className="value">{value.pois[point].societa}</span>
                                                    </div>

                                                    <div className="property">
                                                        <span className="label">Tipologia:</span>
                                                        <span className="value">{value.pois[point].tipologia}</span>
                                                    </div>

                                                    <div className="property">
                                                        <span className="label">Ubicazione:</span>
                                                        <span className="value">{value.pois[point].ubicazione}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </ExpansionPanelDetails>

                                <Divider />

                                <ExpansionPanelActions>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={() => this.hiddenSelection(value, key)}>
                                        {value.visibility ? value.visibility.name : ''}
                                    </Button>
                                    <Button size="small" color="primary" onClick={() => this.exportSelection(value)}>cancella</Button>
                                </ExpansionPanelActions>

                            </ExpansionPanel>

                            <ListItemSecondaryAction className="button-align">
                                <Checkbox
                                    value={key}
                                    edge="end"
                                    onChange={this.handleToggle.bind(this)}
                                    checked={value.selected}
                                    ref="check_me"
                                    inputProps={{ 'aria-labelledby': key }}
                                />


                            </ListItemSecondaryAction>
                        </ListItem>
                    ))
                        : ''}
                </List>

                <div className="button-wrappen">
                    <Button disabled={this.props.drawer.length === 0} size="small" color="secondary" onClick={() => this.cancelAllSelection()}>Cancella tutto</Button>
                </div>
            </Menu>
        )
    }
}

export default Sidebar;
