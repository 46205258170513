export const successUpCircle = (bool)=>{
    return bool ? {
        type:"UP_CIRCLE_SUCCESS",
        data:bool
    }: {
        type: "RESET_UP_CIRCLE",
        data: bool
    }
}

export default successUpCircle;