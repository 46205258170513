const filter = (state = false, action) => {
    switch (action.type) {
        case "SHOW_FILTER":
            return action.data;
        case "HIDE_FILTER":
            return action.data;
        default:
            return state;
    }
}

export default filter;
