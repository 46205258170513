import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import './../style/_loader.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Error extends Component<Props, State> {

    state = {
        openDialog: true
    }

    closeDialog() {
        this.setState({
            openDialog: false
        })
        this.props.onerror(false);
    }

    render() {
        return (
            <Dialog
                open={this.state.openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.closeDialog.bind(this)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                width='70%'
            >
                <DialogTitle id="alert-dialog-slide-title">{"Si è verificato un errore"}</DialogTitle>
                {
                    this.props.warnings.map((item, key) => (
                        <List>
                            <ListItem>
                                <ListItemText inset secondary={item.excel_row} primary={item.message + ' at row '}/>
                            </ListItem>
                            </List>
                    ))
                }
                <DialogActions>
                    <Button onClick={this.closeDialog.bind(this)} variant="contained" color="secondary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default Error;
