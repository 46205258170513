import React, { Component, Fragment } from 'react';
import ReactLoading from 'react-loading';

import './../style/_loader.scss';

class Loader extends Component<Props, State> {

    state = {}

    render() {
        return (
            <Fragment>
                <div className="loader_container">
                    <div className={"generic_loader_text"}>Loading POI</div>
                    <ReactLoading className="generic_loader" type={'bubbles'} color={'#d70b7d'} height={'0%'} width={'10%'} />
                </div>
            </Fragment>
        )
    }
}

export default Loader;
