export const successOffCircle = (bool)=>{
    return bool ? {
        type:"OFF_CIRCLE_SUCCESS",
        data:bool
    }: {
        type: "RESET_OFF_CIRCLE",
        data: bool
    }
}

export default successOffCircle;