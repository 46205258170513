import { SAVE_MAP, UPDATE_MAP, GET_MAP, CREATE_MAP_POST, UPDATE_MAP_POST, GET_MAP_POST, ERROR_MAP, RESET_MAP_PRE, UPDATE_POI } from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';
//const apiTestUrl = 'https://rapport.moose-software.tech/api/test/pois/';
//const apiV1Url = 'https://rapport.moose-software.tech/api/v1/pois/';
const apiV2Url = 'https://rapport.moose-software.tech/api/v3/selections';
const apiV2UrlPost = 'https://rapport.moose-software.tech/api/v3/maps';
const apiGuest = 'https://rapport.moose-software.tech/api/v3/maps/guestsharemap';

export const saveMap = (data) => {
    const emstoken = localStorage.getItem('emstoken');
    const selection = data
    return (dispatch) => {
        return axios.post(`${apiV2Url}` , {selection}, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(saveMapSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const updateMap = (id, data) => {
    const emstoken = localStorage.getItem('emstoken');
    const selection = data
    return (dispatch) => {
        return axios.put(`${apiV2Url}` + '/' + id , {selection}, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(updateMapSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const getSelection = (id) => {
    const emstoken = localStorage.getItem('emstoken');
        return (dispatch) => {
        return axios.get(`${apiV2Url}/` + id, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(getSelectionSuccess(response.data))
                if(response.data.selection.markers.length > 0){
                    const drawed = response.data.selection.markers
                    let marks = {}
                    if(drawed[0].pois){
                        drawed.forEach(function (value, key) {
                            marks = {...marks, ...value.pois}
                        });
                    }
                    const customObj = [{
                        current_results: Object.values(marks).length,
                        data: marks,
                        filters: [],
                        has_next: false,
                        page_number: 0,
                        page_size: 100000,
                        total_results:  Object.values(marks).length,
                        uniqid: "recover"
                    }]

                    dispatch(updatePoiSuccess(customObj))
                }
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const createPostMap = (data) => {
    const emstoken = localStorage.getItem('emstoken');
    const map = data
    
    return (dispatch) => {
        return axios.post(`${apiV2UrlPost}` , {map}, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(createPostMapSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const updateMapPost = (id, data) => {
    const emstoken = localStorage.getItem('emstoken');
    const map = data
    return (dispatch) => {
        return axios.put(`${apiV2UrlPost}/` + id , {map}, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(updateMapPostSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const getMapSelection = (id) => {
    const token = localStorage.getItem('emstoken');
    return (dispatch) => {
        return axios.get(`${apiV2UrlPost}/` + id, { headers: { emstoken: token }})
            .then(response => {
                dispatch(getMapPostSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const getGuestMap = (id) => {
    const emstoken = localStorage.getItem('emstoken');
    return (dispatch) => {
        return axios.get(`${apiGuest}/` + id, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(getMapPostSuccess(response.data))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const saveMapSuccess =  (data) => {
    return {
        type: SAVE_MAP,
        payload: data
        
    }
};

export const updateMapSuccess =  (data) => {
    return {
        type: UPDATE_MAP,
        payload: data
        
    }
};


export const getSelectionSuccess =  (data) => {
    return {
        type: GET_MAP,
        payload: data
        
    }
};

export const createPostMapSuccess =  (data) => {
    return {
        type: CREATE_MAP_POST,
        payload: data
        
    }
};


export const updateMapPostSuccess =  (data) => {
    return {
        type: UPDATE_MAP_POST,
        payload: data
        
    }
};

export const getMapPostSuccess =  (data) => {
    return {
        type: GET_MAP_POST,
        payload: data
    }
};

export const updatePoiSuccess =  (data) => {
    return {
        type: UPDATE_POI,
        payload: data
    }
};

export const getPoiError =  (data) => {
    return {
        type: ERROR_MAP,
        payload: data
        
    }
};

export const resetMap =  (data) => {
    return {
        type: RESET_MAP_PRE,
        payload: data

    }
};



