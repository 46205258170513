import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {canGenerate} from "../redux/actions/canGenerate";
import {exportPoi, generateXls} from "../redux/actions/exportPoi";
import { offCircle } from './../redux/actions/offCircle';
import { offBoundaries } from './../redux/actions/offBoundaries';
import saveMapState from '../redux/actions/saveMapState';
import chunk from "lodash.chunk";

import Slide from '@material-ui/core/Slide';
import { remove } from 'lodash';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class Export extends Component {
    state = {
        chunked: [],
        currentXlsId: '',
        chunkedIndex: 0,
        stopExport: false,
        noData: true,
    };

    exporter = []
    noSelectionOffline = false
    exportedDataFiltered = []

    componentDidMount() {
        const timestamp = new Date().getUTCMilliseconds();
        const uniqueid = 'export' + timestamp
        this.exporter = this.props.drawer
        if(this.props.stateToPass.offlineMode === 'offline_on'){
            if(this.props.successOffCircle || this.props.successOffBoundaries){
                let data = this.props.offCircled.data || this.props.offBoundarized.data
                data = [] 
                const obj = {}
                data = Object.keys(this.props.offBoundarized.data).map((key) => ({name: key !== "" ? key : 'fromimport', pois: this.props.offBoundarized.data[key], type: key !== "" ? key : 'fromimport'}));
                this.exportSelection(data, 0, uniqueid)
                this.setState({
                    uniqueid: uniqueid
                })
            } else {
                const circles = []
                const polygons = []

                for(let i in this.props.drawerOffline){                     
                    if(this.props.drawerOffline[i].type === 'circle'){
                        let pd = this.props.drawerOffline[i]
                        pd.clatlon = pd.lat + ',' + pd.lng
                        circles.push(pd)
                    } else {
                        polygons.push(this.props.drawerOffline[i]);
                    }
                }

                const totPoisFromImport = this.props.drawer.filter(({ type }) => type === "import");
                const totPoisSelection = this.props.drawer.filter(({ type }) => type === "circle" || type === "polygon");

                let importedMarkers = []
                let selectionMarkers = []
                let findduplicates = []
                const dataToExport = []

                for(let i in totPoisSelection){        
                    for(let a in Object.values(totPoisSelection[i].pois)){
                        if(typeof Object.values(totPoisSelection[i].pois)[a] === 'object'){
                            Object.values(totPoisSelection[i].pois)[a].coord = Object.values(totPoisSelection[i].pois)[a].lat + '' + Object.values(totPoisSelection[i].pois)[a].lng
                        }
                    }
    
                    selectionMarkers = selectionMarkers.concat(Object.values(totPoisSelection[i].pois))
                }

                const removeDuplicatesOnSelection = selectionMarkers.filter((v,i,a)=>a.findIndex(v2=>(v2.coord===v.coord))===i)

                for(let i in totPoisFromImport){
                    for(let a in totPoisFromImport[i].pois){
                        totPoisFromImport[i].pois[a].coord = totPoisFromImport[i].pois[a].lat + '' + totPoisFromImport[i].pois[a].lng
                    }               
  
                    importedMarkers = importedMarkers.concat(totPoisFromImport[i].pois)
                    const removeDuplicatesOnImport = importedMarkers.filter((v,i,a)=>a.findIndex(v2=>(v2.coord===v.coord))===i)
                    const hasImport = removeDuplicatesOnSelection.concat(removeDuplicatesOnImport);
                    const cleanMarkers = hasImport.filter(a => typeof a === 'object')

                    const lookup = cleanMarkers.reduce((a, e) => {
                        a[e.coord] = ++a[e.coord] || 0;
                        return a;
                      }, {});
    
                    const mergeXlsOnSelection = cleanMarkers.filter(e => lookup[e.coord] === 1);
                    const removeMerged = mergeXlsOnSelection.filter((v,i,a)=>a.findIndex(v2=>(v2.coord===v.coord))===i)  
                    const obj = {
                        type: 'import',
                        name: 'import' + i,
                        pois: removeMerged
                    }
                    dataToExport.push(obj)
                }              

                //dataToExport.push(removeMerged)

                if(circles.length > 0){
                    this.props.offCircle(circles, this.props.drawer);
                }
                
                if(totPoisFromImport.length > 0) {
                    this.exportedDataFiltered = dataToExport
                    this.exporter = dataToExport
                    this.props.saveOfflineImport(dataToExport)
                }
                
                if(polygons.length > 0){
                    this.props.offBoundaries(polygons, this.props.drawer); // al drawer conviene differenziare import online e offline
                } else if(dataToExport.length > 0) {
                    this.exportSelection(dataToExport, 0, uniqueid)
                    this.exporter = dataToExport
                    this.noSelectionOffline = true
                    this.exportedDataFiltered = dataToExport
                }

                this.setState({
                    uniqueid: uniqueid
                })
                
                this.props.saveMapState(this.props.stateToPass);
                return;
            }
        } else if(this.props.successUpCircle) {
            let data = this.props.successUpCircle ? this.props.upCircle.data : this.props.drawer
            data = [] 
            const obj = {}
            obj.pois = Object.values(this.props.upCircle.data)
            obj.name = 'distance from poi'
            data.push(obj);
            this.exportSelection(data, 0, uniqueid)
            this.setState({
                uniqueid: uniqueid
            })
        } else {
            let data = this.props.drawer
            let filteredData = []

            if(this.props.stateToPass.postMap){
                filteredData = data
            } else {
                filteredData = data.filter(item => typeof item.pois.length === "undefined" || item.name === 'import');
            }
            this.exportSelection(filteredData, 0, uniqueid)
            this.setState({
                uniqueid: uniqueid
            })
        }
    }

    findDuplicates(arr) {
        let index = 0, newArr = [];
        const length = arr.length;

        for (let i = 0; i < length - 1; i++) {
           for (let j = i + 1; j < length; j++) {
           if (arr[i].coord === arr[j].coord) {
                 newArr[index] = arr[i];
                 index++;
              }
           }
        }
        return newArr;
     }

     convertArrayToObject = (array, key) => {
        for(let a in this.props.offlineImport){
            
        }
      };

    componentDidUpdate(prevProps) {
        const data = this.exporter.filter(item => typeof item.pois.length === "undefined" || item.type === 'import');
        if(this.props.exported !== prevProps.exported && this.state.stopExport === false){
            if(this.props.exported === true){
                this.props.stopExport();
            }
            if(this.props.canGenerateRed === false  && this.state.chunkedIndex < this.state.chunked.length -1){
                let selections = {}
                selections.name = data[this.state.index].pois.name ? data[this.state.index].pois.name.slice(0, 30) : 'custom'
                selections.markers = this.state.chunked[this.state.chunkedIndex + 1]
                let pois = []
                pois.push(selections);
                this.props.exportPoi(pois, this.state.currentXlsId, true)
                this.setState({
                    chunkedIndex: this.state.chunkedIndex + 1
                })
            } else if(data.length > 1 && !this.props.successOffCircle && this.props.stateToPass.offlineMode === 'offline_off'){
                const filteredData = data.filter(item => typeof item.pois.length === "undefined" || item.name === 'import');
                if(this.state.currentXlsId === "")return
                const nextIndex = this.state.index + 1
                if(nextIndex > filteredData.length - 1){
                    this.props.canGenerate(true);
                    this.setState({
                        chunked: [],
                        chunkedIndex: 0,
                        stopExport: true
                    })
                    this.props.generateXls(this.state.currentXlsId)
                } else {
                    this.exportSelection(filteredData, nextIndex, this.state.uniqueid)
                }
            } else if(this.props.stateToPass.offlineMode === 'offline_on'){
                /*if(this.props.offBoundarized.data && !this.noSelectionOffline){
                    if(this.props.offBoundarized.data['_global_'].length === 0) {
                        return;
                    }
                }*/

                if(this.noSelectionOffline){
                    const exportingData = this.props.offBoundarized.data ? this.props.offBoundarized.data : this.exportedDataFiltered
                    if(this.state.currentXlsId === "")return
                    const nextIndex = this.state.index + 1
                    if(nextIndex > exportingData.length - 1){
                        this.props.canGenerate(true);
                        this.setState({
                            chunked: [],
                            chunkedIndex: 0,
                            stopExport: true
                        })
                        this.props.generateXls(this.state.currentXlsId)
                    } else {
                        this.exportSelection(this.exporter, nextIndex, this.state.uniqueid)
                    }
                } else {
                    const exportingData = this.props.offBoundarized.data ? this.props.offBoundarized.data : this.exportedDataFiltered
                    if(this.props.offlineImport.length > 0 && !this.noSelectionOffline){
                        for(let a in this.props.offlineImport){
                            exportingData[this.props.offlineImport[a].name] = this.props.offlineImport[a].pois.reduce(
                                (obj, item) => Object.assign(obj, { [item._id._id]: item }), {}); 
                        }
                    }
                    const newData = [];
                    const fromApi = Object.entries(exportingData);
                    const filtered = fromApi.filter(value => (
                        Object.values(value[1]).length > 1
                        ));
                    const filteredData = Object.fromEntries(filtered);
                    const keys = Object.keys(filteredData)
                    for(let i in keys){
                        filteredData[keys[i]].name = keys[i] !== "" ? keys[i] : "fromimport"
                    }
                    
                    const dataToExport = Object.values(filteredData);
                    for(let i in dataToExport){
                        const obj = {};
                        obj.pois = dataToExport[i]
                        newData.push(obj)
                    }
                    if(this.state.currentXlsId === "")return
                    const nextIndex = this.state.index + 1
                    if(nextIndex > newData.length - 1){
                        this.props.canGenerate(true);
                        this.setState({
                            chunked: [],
                            chunkedIndex: 0,
                            stopExport: true
                        })
                        this.props.generateXls(this.state.currentXlsId)
                    } else {
                        this.exportSelection(newData, nextIndex, this.state.uniqueid)
                    }
                }
            } else {
                if(this.state.currentXlsId === "")return
                this.props.canGenerate(true);
                this.setState({
                    chunked: [],
                    chunkedIndex: 0,
                    stopExport: true
                })
                this.props.generateXls(this.state.currentXlsId)
            }
        }

        if(this.props.generatedXls !== prevProps.generatedXls){
            this.setState({
                exportxls: this.props.generatedXls.url,
                stopExport: false
            })
            window.location.href = this.props.generatedXls.url
            this.props.stopExport();
            //window.open(this.props.generatedXls.url, '_blank')
        }
    }

    exportSelection(data, index, unid) {    
        if(data.length === 0){
            return
        }
        const pois = []
        let i = index;
        let chunked = []
        let exportingData = []
        let selections = {}
        let drawed = Object.values(data[i].pois)
        if(data[i].pois.name){
            const names = data[i].pois.name.replace(':','')
            selections.name = names.slice(0, 30)
            const popped = drawed.length > 1 ? drawed.pop() : drawed
        } else {
            selections.name = data[i].name ? data[i].name : 'customoffline'
        }
        chunked = chunk(drawed, 5000)
        this.setState({
            chunked: chunked,
            index: index
        })
        this.sendPoiForExport(chunked, selections, pois, unid, exportingData)

        this.setState({
            currentXlsId: unid,
        })

    }

    sendPoiForExport(chunked, selections, pois, unid, exportingData){
        selections.markers = chunked[0]
        pois = []
        pois.push(selections);
        exportingData.push(selections)
        this.setState({
            chunkedIndex: 0,
        })
        if(selections.markers){
            this.props.exportPoi(pois, unid, true)
            this.props.canGenerate(false)
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.drawer.length === 0 ? 'NESSUN ELEMENTO SELEZIONATO' : 'ESPORTAZIONE IN CORSO'}
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        exported: state.exported,
        generatedXls: state.generatedXls,
        canGenerateRed: state.canGenerateRed,
        drawer: state.drawer,
        successOffBoundaries: state.successOffBoundaries,
        successOffCircle: state.successOffCircle,
        offCircled: state.offCircle,
        offBoundarized: state.offBoundaries,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        canGenerate: (data) => {
            dispatch(canGenerate(data));
        },
        exportPoi: (data, uniqid, hasNext) => {
            dispatch(exportPoi(data, uniqid, hasNext));
        },
        generateXls: (uniqid) => {
            dispatch(generateXls(uniqid));
        },
        saveMapState: (data) => {
            dispatch(saveMapState(data));
        },
        offCircle: (data, selections) => {
            dispatch(offCircle(data, selections));
        },
        offBoundaries: (data, selections) => {
            dispatch(offBoundaries(data, selections));
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Export);
