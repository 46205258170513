export const GET_POI = 'GET_POI';
export const ERROR_POI = 'ERROR_POI';
export const FETCH_POI = 'FETCH_POI';

export const MOVE_POI = 'MOVE_POI';

export const GET_FILTERS = 'GET_FILTERS';
export const ERROR_FILTERS = 'ERROR_FILTERS';
export const FETCH_FILTERS = 'FETCH_FILTERS';

export const SAVE_FILTERS = 'SAVE_FILTERS';
export const RESET_FILTER = 'RESET_FILTER';

export const SAVE_DRAWER = 'SAVE_DRAWER';
export const RESET_DRAWER = 'RESET_DRAWER';

export const SAVE_DRAWER_OFFLINE = 'SAVE_DRAWER_OFFLINE';
export const RESET_DRAWER_OFFLINE = 'RESET_DRAWER_OFFLINE';

export const SAVE_LEGEND = 'SAVE_LEGEND';

export const EDITING_LEGEND = 'EDITING_LEGEND';

export const SAVE_MAP = 'SAVE_MAP';
export const UPDATE_MAP = 'UPDATE_MAP';
export const GET_MAP = 'GET_MAP';
export const CREATE_MAP_POST = 'CREATE_MAP_POST';
export const UPDATE_MAP_POST = 'UPDATE_MAP_POST';
export const GET_MAP_POST = 'GET_MAP_POST';
export const ERROR_MAP = 'ERROR_MAP';
export const RESET_MAP_PRE = 'RESET_MAP_PRE';
export const UPDATE_POI = 'UPDATE_POI';

export const ERROR_EXPORT = 'ERROR_EXPORT';
export const EXPORT_POI = 'EXPORT_POI';
export const RESET_EXPORT = 'RESET_EXPORT';
export const FETCHING_EXPORT = 'FETCHING_EXPORT'

export const ERROR_IMPORT = 'ERROR_IMPORT';
export const IMPORT_POI = 'IMPORT_POI';

export const ERROR_M_RADIUS = 'ERROR_M_RADIUS';
export const GET_M_RADIUS = 'GET_M_RADIUS';

export const GET_FILTERS_POST = 'GET_FILTERS_POST';
export const FETCH_FILTERS_POST = 'FETCH_FILTERS_POST';

export const GENERATE_XLS_SUCCESS = 'GENERATE_XLS_SUCCESS';
export const GENERATE_XLS_ERROR = 'GENERATE_XLS_ERROR';

export const CAN_GENERATE = 'CAN_GENERATE';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const ERROR_UPLOAD = 'ERROR_UPLOAD';

export const UPCIRCLE_POI = 'UPCIRCLE_POI';
export const OFFCIRCLE_POI = 'OFFCIRCLE_POI';
export const OFFBOUNDARIES_POI = 'OFFBOUNDARIES_POI';
export const UP_CIRCLE_SUCCESS = 'UP_CIRCLE_SUCCESS';
export const UP_CIRCLE_ERROR = 'UP_CIRCLE_ERROR';
export const OFF_CIRCLE_SUCCESS = 'OFF_CIRCLE_SUCCESS';
export const OFF_CIRCLE_ERROR = 'OFF_CIRCLE_ERROR';
export const OFF_BOUNDARIES_SUCCESS = 'OFF_BOUNDARIES_SUCCESS';
export const OFF_BOUNDARIES_ERROR = 'OFF_BOUNDARIES_ERROR';

export const SAVE_OFFLINE_IMPORT = 'SAVE_OFFLINE_IMPORT';
export const RESET_OFFLINE_IMPORT = 'RESET_OFFLINE_IMPORT';

export const GET_EXCEL_M_CIRCLES = 'GET_EXCEL_M_CIRCLES';
export const ERROR_EXCEL_M_CIRCLES = 'ERROR_EXCEL_M_CIRCLES'


