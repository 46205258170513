import {OFFBOUNDARIES_POI, UP_BOUNDARIES_SUCCESS, OFF_BOUNDARIES_ERROR } from './types';
import axios from 'axios';
import onerror from './error';
import loading from './loader';
import successOffCircle from './successOffCircle';

const apiV2Url = 'https://rapport.moose-software.tech/api/v3/pois/offmboundaries';

export const offBoundaries = ( params, selections ) => {
    const emstoken = localStorage.getItem('emstoken');

    let i = 0;
    const obj = {};

    for (i = 0; i <  selections.length; i++) {
        if(selections[i].type === "import"){
            const pois = Object.values(selections[i].pois)
            const name = "import"
            obj[name] = pois
        } else if(selections[i].pois.name){
            const pois = Object.values(selections[i].pois)
            const name = selections[i].name
            obj[name] = pois
        }
    }

    obj.polygons = params;

    return (dispatch) => {
        return axios.post(`${apiV2Url}` ,obj, { headers: { emstoken: emstoken }})
            .then(response => {
                dispatch(success(response.data))
                dispatch(successOffCircle(true))
            })
            .catch(error => {
                dispatch(onerror(true));
                dispatch(loading(false));
                throw(error);
            });
    };
};

export const success =  (data) => {
    return {
        type: OFFBOUNDARIES_POI,
        payload: data
        
    }
};

export const error =  (data) => {
    return {
        type: OFF_BOUNDARIES_ERROR,
        payload: data
    }
};

