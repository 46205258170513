import { SAVE_DRAWER_OFFLINE, RESET_DRAWER_OFFLINE } from "../actions/types";

export default function savedDrawerOffline(state = [], action) {
    switch (action.type) {
        case SAVE_DRAWER_OFFLINE:
            return action.payload;
        case RESET_DRAWER_OFFLINE:
            return [];
        default:
    }

    return state;
}
