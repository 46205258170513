import { SAVE_MAP, UPDATE_MAP, GET_MAP, RESET_MAP_PRE } from '../actions/types';

export default function mapReducer(state = [], action) {
    switch (action.type) {
        case SAVE_MAP:
            return action.payload;
        case UPDATE_MAP:
            return action.payload;
        case GET_MAP:
            return action.payload;
        case RESET_MAP_PRE:
            const data = action.payload
            if(data.length > 0){
                data.selection.markers = [];
            }

            return data
        default:
            return state;
    }
}
