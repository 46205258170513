import {SAVE_DRAWER, RESET_DRAWER} from "./types";

export function saveDrawer(payload) {
    return {
        type: SAVE_DRAWER,
        payload: payload
    };
}

export function resetDrawer(payload) {
    return {
        type: RESET_DRAWER,
        payload: payload
    };
}
